import React from 'react'

const IndexPage = () => (
  <div>
    <meta charSet='utf-8' />
    <meta
      name='viewport'
      content='width=device-width, initial-scale=1, shrink-to-fit=no'
    />
    <meta name='description' content />
    <meta
      name='author'
      content='Mark Otto, Jacob Thornton, and Bootstrap contributors'
    />
    <meta name='generator' content='Jekyll v3.8.6' />
    <title>OnlinePunks - Download Page</title>
    <link
      rel='canonical'
      href='https://getbootstrap.com/docs/4.4/examples/offcanvas/'
    />
    {/* Bootstrap core CSS */}
    <link
      href='/docs/4.4/dist/css/bootstrap.min.css'
      rel='stylesheet'
      integrity='sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh'
      crossOrigin='anonymous'
    />
    {/* Favicons */}
    <link
      rel='apple-touch-icon'
      href='/docs/4.4/assets/img/favicons/apple-touch-icon.png'
      sizes='180x180'
    />
    <link
      rel='icon'
      href='/docs/4.4/assets/img/favicons/favicon-32x32.png'
      sizes='32x32'
      type='image/png'
    />
    <link
      rel='icon'
      href='/docs/4.4/assets/img/favicons/favicon-16x16.png'
      sizes='16x16'
      type='image/png'
    />
    <link rel='manifest' href='/docs/4.4/assets/img/favicons/manifest.json' />
    <link
      rel='mask-icon'
      href='/docs/4.4/assets/img/favicons/safari-pinned-tab.svg'
      color='#563d7c'
    />
    <link rel='icon' href='/docs/4.4/assets/img/favicons/favicon.ico' />
    <meta
      name='msapplication-config'
      content='/docs/4.4/assets/img/favicons/browserconfig.xml'
    />
    <meta name='theme-color' content='#563d7c' />
    <link
      rel='stylesheet'
      href='https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css'
      integrity='sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T'
      crossorigin='anonymous'
    />

    <style
      dangerouslySetInnerHTML={{
        __html:
          '\n      .bd-placeholder-img {\n        font-size: 1.125rem;\n        text-anchor: middle;\n        -webkit-user-select: none;\n        -moz-user-select: none;\n        -ms-user-select: none;\n        user-select: none;\n      }\n\n      @media (min-width: 768px) {\n        .bd-placeholder-img-lg {\n          font-size: 3.5rem;\n        }\n      }\n    ',
      }}
    />
    {/* Custom styles for this template */}
    <link href='offcanvas.css' rel='stylesheet' />
    <main role='main' className='container'>
      <div className='d-flex align-items-center p-3 my-3 text-white-50 bg-black rounded shadow-sm'>
        <img
          className='mr-3'
          src='OP_Logo_white.png'
          alt=''
          width={300}
          height={48}
        />
        <div className='lh-100'>
          <h6 className='mb-0 text-white lh-100'></h6>
          <small>Social Media Knowledge since 2019</small>
        </div>
      </div>
      <div className='my-3 p-3 bg-white rounded shadow-sm'>
        <h6 className='border-bottom border-gray pb-2 mb-0'>Downloads</h6>
        <div className='media text-muted pt-3'>
          <svg
            className='bd-placeholder-img mr-2 rounded'
            width={32}
            height={32}
            xmlns='http://www.w3.org/2000/svg'
            preserveAspectRatio='xMidYMid slice'
            focusable='false'
            role='img'
            aria-label='Placeholder: 32x32'
          >
            <title>Placeholder</title>
            <rect width='100%' height='100%' fill='#007bff' />
            <text x='50%' y='50%' fill='#007bff' dy='.3em'>
              32x32
            </text>
          </svg>
          <div className='media-body pb-3 mb-0 small lh-125 border-bottom border-gray'>
            <div className='d-flex justify-content-between align-items-center w-100'>
              <strong className='text-gray-dark'>Facebook Bible 2020</strong>
              <a href='#'>Download</a>
            </div>
            <span className='d-block'>by Patrick Wind</span>
          </div>
        </div>
      </div>
    </main>
  </div>
)

export default IndexPage
